import React, { FC, useEffect } from "react"

export const PerfectVenueForm: FC = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.perfectvenue.com/v1/index.js"
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return <div data-pv-venue-contact-form-id="b9b0155545"></div>
}
